import Avatar from "@src/components/atoms/avatar";
import cn from "classnames";
import { useRouter } from "next/navigation";

interface ProfileCardProps {
  givenName?: string;
  profileImage?: string;
  tokenName: string;
  isActiveProfile?: boolean;
}
export default function ProfileCard({
  givenName,
  profileImage,
  tokenName,
  isActiveProfile = false,
}: ProfileCardProps) {
  const router = useRouter();
  return (
    <div
      onClick={() => router.push(`/${tokenName}`)}
      className="dark:bg-white bg-dark-neutral-800 p-2 rounded-xl dark:bg-opacity-5 bg-opacity-5  relative cursor-pointer dark:hover:bg-opacity-10 hover:bg-opacity-10"
    >
      <div className="flex items-center gap-2">
        <Avatar src={profileImage} size={32} />
        <div className="flex body14 font-medium flex-col pr-2">
          <p className="dark:text-white line-clamp-2 ">{givenName ?? "DATA MISSING"}</p>
          <p className="dark:text-gray-400 line-clamp-2">@{tokenName}</p>
        </div>
      </div>
      <div
        className={cn(
          "absolute h-[10px] w-[10px] flex items-center justify-center top-2 right-2  rounded-full",
          isActiveProfile ? "bg-brand" : "border border-gray-400"
        )}
      >
        {isActiveProfile && (
          <div className="bg-gray-400 rounded-full h-1 w-1"></div>
        )}
      </div>
    </div>
  );
}
