import AnchorLink from "@src/components/atoms/links/anchor-link";
import LogoComponent from "@src/components/atoms/logoComponent";
import { socialLinks } from "@src/constants";
import { useScrollContext } from "@src/context/scroll-context";
import { useParams, useRouter } from "next/navigation";
import React , {useEffect, useState} from "react";

export default function Footer() {
  const { scrollToSection, howItWorksRef, featuresRef, faqRef } =
  useScrollContext();
  const router = useRouter();
  const params = useParams();
  const [currentYear , setCurrentYear] = useState("")

  const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
    if (params?.page !== undefined) {
      router.replace("/");
    }
    scrollToSection(ref);
  };

  const primaryLinks = [
    {
      label: "Home",
      onClick: () => router.replace("/"),
    },
    {
      label: "About us",
      onClick: () => {},
    },
    {
      label: "Features",
      onClick: () => handleNavigation(featuresRef),
    },
    {
      label: "How it Works",
      onClick: () => handleNavigation(howItWorksRef),
    },
  ];

  const secondaryLinks = [
    {
      label: "Docs",
      onClick: () => router.push("/docs"),
    },
    {
      label: "FAQs",
      onClick: () => handleNavigation(faqRef),
    },
  ];

  useEffect(()=>{
    setCurrentYear((new Date).getFullYear().toString())
  },[])

  return (
    <div className="flex flex-col w-full py-20 px-5 lg:px-[158px] gap-16 max-md:gap-8">
      <div className="flex">
        {primaryLinks.map((primaryLink, index) => (
          <div
            key={index}
            className="body20 max-md:text-[16px] max-md:leading-[30px] max-md:tracking-[-0.33px] font-medium flex gap-x-4 max-md:gap-x-2 cursor-pointer"
          >
            <span onClick={primaryLink.onClick}>{primaryLink.label}</span>
            {index < primaryLinks.length - 1 && (
              <span className="mr-4 max-md:mr-2">/</span>
            )}
          </div>
        ))}
      </div>
      <div className="w-full h-[1px] opacity-20 bg-white" />
      <div className="flex max-md:flex-col">
        <div className="flex justify-between max-md:flex-col max-md:gap-y-8">
          <div className="flex flex-col gap-12 w-[318px]">
            <div className="flex flex-col gap-y-4">
              <p className="body20 font-semibold">Contact Us</p>
              <p className="body16 text-gray-400">
                support@drep.id
              </p>
            </div>
            <div className="flex flex-col gap-y-4">
              <p className="body20 font-semibold">Location</p>
              <p className="body16 text-gray-400">
                483920, Buddhanagar, <br></br> Kathmandu 22/2/5, Office 4
              </p>
            </div>
          </div>
          <div className="flex flex-col w-[318px]">
            <p className="body20 font-semibold mb-6">Quick Links</p>
            <div className="body16 font-medium flex flex-col text-gray-400 gap-y-4 ">
              {secondaryLinks.map((secondaryLink, index) => (
                <p
                  key={index}
                  onClick={secondaryLink.onClick}
                  className="cursor-pointer"
                >
                  {secondaryLink.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full gap-16 items-end max-md:items-start max-md:mt-8">
          <LogoComponent />
          <div className="flex flex-col items-end gap-6 max-md:items-start">
            <div className="h-[1px] w-[45px] bg-white" />
            <p className="text-gray-400 text-end max-md:text-start">
              DRep ID is a decentralized platform helping <br /> users create
              unique, URL-friendly identities for <br /> governance and
              decentralized voting.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex gap-2">
          {socialLinks.map((socialLink, index) => (
            <AnchorLink
              href={socialLink.href}
              className="bg-dark-neutral-800 rounded-full h-12 w-12 flex justify-center items-center cursor-pointer group"
              key={index}
              target="new"
            >
              <socialLink.icon className="group-hover:text-primary-700 transition duration-500" />
            </AnchorLink>
          ))}
        </div>
        <div className="w-full h-[1px] bg-gray-200 opacity-10" />
      </div>
      <div className="flex justify-between max-md:flex-col max-md:gap-y-6">
        <div className="body16 font-medium text-gray-400 flex gap-6">
          <AnchorLink
          //@ts-ignore`
            href={"/docs/privacy-policy"}
            target="new"
          >
            <p className="body16">Privacy Policy</p>
          </AnchorLink>
          <AnchorLink
          //@ts-ignore`
            href={"/docs/terms-of-service"}
            target="new"
          >
            <p className="body16">Terms and Conditions</p>
          </AnchorLink>
        </div>
        <p className="body16 font-medium text-gray-400">
          © {currentYear} Sireto Technology. All rights reserved.
        </p>
      </div>
    </div>
  );
}
