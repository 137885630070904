import Dropdown from "@src/components/atoms/dropdown/dropdown";
import { useModal } from "@src/components/modal-views/context";
import showToast from "@src/components/molecules/toast";
import { ToastId } from "@src/constants/toastId";
import { useDRepLogoutMutation } from "@src/store/drep/api";
import type { RootState } from "@src/store/store";
import { removeWallet } from "@src/store/user/wallet";
import cn from "classnames";
import { useParams, useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import LucidAvatar from "@src/components/icons/lucidAvatar";
import { MenuItem } from "@headlessui/react";
import ProfileCard from "@src/components/cards/profileCard";
import Avatar from "@src/components/atoms/avatar";
import React from "react";
import AddAvatarIcon from "@src/components/icons/addAvatar";

interface ProfileDropDownProps {
  label: string;
  profileImage?: string;
  tokenList?: Array<string>;
  givenName?: string;
}

export default function ProfileDropDown({
  label,
  profileImage,
  tokenList,
  givenName,
}: ProfileDropDownProps) {
  const { openModal, closeModal } = useModal();
  const [dRepLogout] = useDRepLogoutMutation();
  const dispatch = useDispatch();
  const wallet = useSelector((state: RootState) => state.wallet);
  const router = useRouter();
  const params = useParams();
  const isInProfilePage = params && "dRepName" in params;
  const handleDisconnectWallet = async () => {
    if (wallet.instance !== null) {
      try {
        await dRepLogout().unwrap();
        dispatch(removeWallet());
        if (!isInProfilePage) router.replace("/");
        closeModal();
      } catch (error) {
        console.error("Failed to logout:", error);
      }
    } else {
      showToast({
        description: "wallet connection error",
        toastId: ToastId.ERROR_TOAST,
        type: "error",
      });
    }
  };
  const getActiveProfileName = ({
    isProfileCard = false,
  }: {
    isProfileCard?: boolean;
  } = {}): string => {
    if (tokenList && tokenList.length > 0) {
      if (!isInProfilePage) return tokenList[0];
      const activeToken = tokenList.find((token) => params?.dRepName === token);
      return isProfileCard ? activeToken || "" : activeToken || tokenList[0];
    }
    return "";
  };


  const renderMyProfileContent = () =>
    tokenList &&
    tokenList.length > 0 && (
      <MenuItem key="renderProfile">
        <div
          onClick={() => router.replace(`/${getActiveProfileName()}`)}
          className="flex p-3 items-center cursor-pointer hover:bg-gray-100 dark:hover:bg-dark-neutral-800 font-medium body16 gap-2"
        >
          <LucidAvatar className="dark:text-white text-dark-neutral-700" />
          <p className="dark:text-white">My profile</p>
          <p className="text-gray-400">@{getActiveProfileName()}</p>
        </div>
      </MenuItem>
    );

  const renderMintDRepContent = () => (
    <MenuItem key="renderDisconnectWallet">
      <div
        className={cn(
          "flex p-3 border-y dark:border-dark-neutral-700 items-center gap-2 cursor-pointer dark:hover:bg-dark-neutral-800 hover:bg-gray-100",
        )}
        onClick={() =>
          router.push("/drep/mint")
        }
      >
        <AddAvatarIcon className="dark:text-white text-dark-neutral-700"/>
        <p className="text-ellipsis font-medium dark:text-white body16 overflow-hidden whitespace-nowrap">
          Mint DRep ID
        </p>
      </div>
    </MenuItem>
  )

  const renderDisconnectWalletContent = () => (
    <MenuItem key="renderDisconnectWallet">
      <div
        className={cn(
          "flex p-3 border-y dark:border-dark-neutral-700 items-center gap-2 cursor-pointer dark:hover:bg-dark-neutral-800 hover:bg-gray-100",
        )}
        onClick={() =>
          openModal("DISCONNECT_WALLET", { handleDisconnectWallet })
        }
      >
        <img
          src={wallet.icon}
          alt="Connected wallet icon"
          className="h-[18px] w-[18px] object-cover"
        />
        <p className="text-ellipsis font-medium dark:text-white body16 overflow-hidden whitespace-nowrap">
          Disconnect Wallet
        </p>
      </div>
    </MenuItem>
  );

  const renderSwitchAccountContent = () =>
    tokenList &&
    tokenList.length > 0 && (
      <MenuItem key="renderSwitchAccount">
        <div className="flex flex-col py-3 px-4">
          <p className="label12 dark:text-white font-semibold">
            SWITCH ACCOUNT
          </p>
          <div className="flex flex-col mt-4 gap-2 max-h-[50vh] overflow-y-auto">
            {tokenList.map((token) => (
              <ProfileCard
                key={token}
                givenName={givenName}
                profileImage={profileImage}
                tokenName={token}
                isActiveProfile={
                  getActiveProfileName({
                    isProfileCard: true,
                  }).toLowerCase() === token.toLowerCase()
                }
              />
            ))}
          </div>
        </div>
      </MenuItem>
    );

  const menuItems = [
    renderMyProfileContent(),
    renderMintDRepContent(),
    renderDisconnectWalletContent(),
    renderSwitchAccountContent(),
  ].filter((item) => React.isValidElement(item));

  return (
    <Dropdown
      contentWidth={tokenList && tokenList.length != 0 ? 260 : 180}
      label={label}
      prefixIcon={<Avatar size={24} src={profileImage} />}
      menuItems={menuItems}
    />
  );
}
