import type { SearchDRepMetadataResponse } from "@src/store/search/types";
import AvatarIcon from "../icons/avatarIcon";
import { getValueByPath } from "@src/utils/metadataUtils";
import { searchLabel } from "@src/constants/searchLabel";
import type { TokenType } from "@src/store/drep/types";
import Badge from "../atoms/badges/badge";
import CustomizeToolTip from "../atoms/tooltip";
import type { DRepStatusType } from "@src/models/types/dRep";
import { capitalize } from "@src/utils/stringUtils";

interface SearchCardProps {
  item: SearchDRepMetadataResponse;
  token?: TokenType | null;
}

export default function SearchCard({ item, token }: SearchCardProps) {
  const getBadgeColor = (status?: DRepStatusType) => {
    switch (status) {
      case "Active":
        return "success";
      case "InActive":
        return "warning";
      default:
        return "error";
    }
  };
  return (
    <div className="flex flex-col gap-2 dark:bg-dark-neutral-800 bg-white rounded-lg p-3">
      <div className="flex gap-2 justify-between items-center">
        <div className="flex items-center gap-2 label12 font-medium">
          {item.image ? (
            <img
              src={item.image}
              alt="Previous DRep"
              className="w-6 h-6 rounded-full"
            />
          ) : (
            <AvatarIcon className="h-6 w-6" />
          )}
          <p className="dark:text-white">{item.givenName}</p>
          {token && (
            <>
              <div className="w-[1px] dark:bg-white"></div>
              <p className="dark:text-gray-400">{token.name}</p>
            </>
          )}
        </div>
        {item.status && (
          <Badge
            label={capitalize(item.status)}
            color={getBadgeColor(item.status)}
          />
        )}
      </div>
      <hr className="w-full h-[1px] "></hr>
      <div className="gap-1 flex flex-col">
        <p className="truncate flex gap-1 label12">
          <span className="font-medium min-w-[140px] dark:text-white">
            Given Name:
          </span>
          <span className="flex-1 dark:text-gray-400">
            {item.givenName}
          </span>
        </p>
        <p className="truncate flex gap-1 label12">
          <span className="font-medium min-w-[140px] dark:text-white">
            DRep ID (CIP 105):
          </span>
          <span className="flex-1 dark:text-gray-400">
            {item.meta.drepIdCip105}
          </span>
        </p>
        {Object.keys(item.highlight).map((key, index) => (
          <p key={key} className="label12 flex gap-1">
            <span className="font-medium dark:text-white min-w-[140px]  text-neutral-600">
              {getValueByPath(searchLabel, key)}:
            </span>
            <span
              data-tooltip-id={item.givenName + index}
              data-tooltip-place="bottom-start"
              className="break-words flex-1 truncate dark:text-gray-400 search-value"
              dangerouslySetInnerHTML={{
                //@ts-ignore
                __html: item.highlight[key],
              }}
            ></span>
            {
              //@ts-ignore`
              item.highlight[key].toString().length > 64 && (
                <CustomizeToolTip
                  className="search-value"
                  id={item.givenName + index}
                  description={
                    //@ts-ignore
                    item.highlight[key].toString()
                  }
                />
              )
            }
          </p>
        ))}
      </div>
    </div>
  );
}
